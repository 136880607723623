<div class="set-password-screen" [ngClass]="{ 'white-mode': !isDarkTheme() }">
    <div class="left-panel">
        <div class="set-password-header-container">
            <div class="header-welcome-text">
                Choisissez un mot de passe personnel pour votre compte
            </div>
            <div class="logo-container">
                <div class="icon-cadenas"></div>
            </div>
        </div>
        <div class="set-password-form-container">
            <form
                class="form"
                [formGroup]="setPasswordForm"
                (ngSubmit)="setPassword()"
                autocomplete="off"
            >
                <div class="password-container">
                    <input
                        type="{{ getPasswordInputType() }}"
                        class="input"
                        placeholder="Saisissez votre mot de passe"
                        autocomplete="off"
                        required
                        id="password"
                        formControlName="password"
                    />
                    <span
                        class="icon-voir"
                        *ngIf="canViewPassword()"
                        (click)="togglePasswordPreview()"
                        (touch)="togglePasswordPreview()"
                    ></span>
                </div>
                <div class="password-container">
                    <input
                        type="{{ getPasswordConfirmationInputType() }}"
                        class="input"
                        placeholder="Confirmer votre mot de passe"
                        autocomplete="off"
                        required
                        id="confirmation_password"
                        formControlName="confirmation_password"
                    />
                    <span
                        class="icon-voir"
                        *ngIf="canViewConfirmationPassword()"
                        (click)="togglePasswordConfirmationPreview()"
                        (touch)="togglePasswordConfirmationPreview()"
                    ></span>
                </div>

                <div class="set-password-button-container">
                    <button class="set-password-button" type="submit">Valider</button>
                </div>
            </form>
            <div class="message-container">
                <div class="error-message-container" *ngIf="errorMessage">
                    <div class="icon-Warning"></div>
                    <div class="error-message-value">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="information-message-container" *ngIf="!errorMessage">
                    <div class="information-message-value">
                        <br />
                        Votre mot de passe doit respecter les critères suivants :
                        <ul>
                            <li>8 caractères minimum</li>
                            <li>
                                Au moins 3 des 4 conditions suivantes :
                                <ul>
                                    <li>majuscules</li>
                                    <li>minuscules</li>
                                    <li>chiffres</li>
                                    <li>caractères spéciaux ($ / # &#64; £ ...)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="set-password-footer-container">
            <div class="uimm-logo-container">
                <img [src]="getImageBottom()" alt="logo uimm" class="logo-uimm" />
            </div>
            <div class="footer-links">
                <span class="link" (click)="openDiagnostic()">Diagnostic de connexion</span>
                <span class="separator">|</span>
                <span class="link" (click)="goToCGU()">Informations légales</span>
                <span class="separator">|</span>
                <span class="version">{{ getVersion() }}</span>
            </div>
        </div>
    </div>

    <div class="right-panel" [ngStyle]="getRightSidePanelBackground()">
        <img class="anchored-logo" [src]="getAnchoredLogoURL()" />
    </div>
</div>
