import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { pairwise, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { UsersService } from '../../../services/users.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private usersService: UsersService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService
    ) {}

    subscriptions: Subscription = new Subscription();

    errorMessage: string;

    viewPassword = false;
    passwordPreview = false;
    viewConfirmationPassword = false;
    passwordConfirmationPreview = false;

    setPasswordForm: UntypedFormGroup;

    userid: number;
    token: string;

    ngOnInit() {
        this.userid = +this.route.snapshot.queryParamMap.get('userid');
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.setPasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmation_password: ['', Validators.required]
        });
    }

    ngAfterViewInit() {
        this.initPasswordRevealListener();
        this.setPasswordForm.get('password').setValue('');
        this.setPasswordForm.get('confirmation_password').setValue('');
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Initialise le listener qui surveille les copier/coller dans l'input password
     * Désactive le reveal password si besoin
     */
    initPasswordRevealListener() {
        this.setPasswordForm
            .get('password')
            .valueChanges.pipe(
                pairwise(),
                map((values: Array<string>) => {
                    const [oldValue, newValue] = values;
                    const oldValueLength = Math.abs(oldValue.length);
                    const newValueLength = Math.abs(newValue.length);
                    if (Math.abs(newValueLength - oldValueLength) > 1) {
                        this.viewPassword = false;
                    } else if (newValue === '') {
                        this.viewPassword = true;
                    } else if (
                        newValueLength === 1 &&
                        (oldValueLength >= 7 || oldValueLength === 0)
                    ) {
                        this.viewPassword = true;
                    }
                })
            )
            .subscribe();
        this.setPasswordForm
            .get('confirmation_password')
            .valueChanges.pipe(
                pairwise(),
                map((values: Array<string>) => {
                    const [oldValue, newValue] = values;
                    const oldValueLength = Math.abs(oldValue.length);
                    const newValueLength = Math.abs(newValue.length);
                    if (Math.abs(newValueLength - oldValueLength) > 1) {
                        this.viewConfirmationPassword = false;
                    } else if (newValue === '') {
                        this.viewConfirmationPassword = true;
                    } else if (
                        newValueLength === 1 &&
                        (oldValueLength >= 7 || oldValueLength === 0)
                    ) {
                        this.viewConfirmationPassword = true;
                    }
                })
            )
            .subscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): any {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    setPassword(): void {
        if (
            this.setPasswordForm.value.password === this.setPasswordForm.value.confirmation_password
        ) {
            this.subscriptions.add(
                this.usersService
                    .setPassword(this.userid, this.setPasswordForm.value.password, this.token)
                    .subscribe(
                        (data: any) => {
                            this.loginService.clearExceptionAndNavigate();
                        },
                        (error: HttpErrorResponse) => {
                            this.errorMessage = error.error.userMessage;
                        }
                    )
            );
        } else {
            this.errorMessage =
                'Les mots de passe que vous avez renseignés ne sont pas identiques.';
        }
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }

    /**
     * Autorise le reveal password si celui-ci existe et si l'utilisateur n'a pas copié/collé
     */
    canViewPassword(): boolean {
        return this.viewPassword && this.setPasswordForm.get('password').value.length > 0;
    }

    canViewConfirmationPassword(): boolean {
        return (
            this.viewConfirmationPassword &&
            this.setPasswordForm.get('confirmation_password').value.length > 0
        );
    }

    getPasswordInputType(): string {
        return this.passwordPreview && this.viewPassword ? 'text' : 'password';
    }

    getPasswordConfirmationInputType(): string {
        return this.passwordConfirmationPreview && this.viewConfirmationPassword
            ? 'text'
            : 'password';
    }

    /**
     * Au clic maintenu l'input password révèle le mot de passe
     */
    togglePasswordPreview() {
        this.passwordPreview = !this.passwordPreview;
    }

    /**
     * Au clic maintenu l'input password révèle le mot de passe
     */
    togglePasswordConfirmationPreview() {
        this.passwordConfirmationPreview = !this.passwordConfirmationPreview;
    }
}
